import React from 'react';
import jsPDF from 'jspdf';
import { useLocation } from 'react-router-dom';
import { Box, Typography, Button } from '@mui/material';
import { formatNumberWithCommas } from '../../Helper/Helper'; // Import your helper function

const ViewCommProject = () => {
  const location = useLocation();
  const paymentData = location.state?.paymentData;

  const handleDownloadPDF = () => {
    const doc = new jsPDF();
    doc.setFontSize(12);
    doc.text('LSV PROPERTY GROUP SDN BHD', 20, 20);
    doc.text(`PAY TO: ${paymentData.requestor}`, 20, 40);
    doc.text(`PAYMENT AMOUNT: RM ${formatNumberWithCommas(paymentData.commission)}`, 20, 60);
    doc.text(`DESCRIPTION: ${paymentData.type} Commission Payment`, 20, 80);
    doc.text(`DATE: ${paymentData.requestedAt}`, 20, 100);
    doc.save('payment-voucher.pdf');
  };

  return (
    <Box sx={{ padding: 3 }}>
      <Typography variant="h4" gutterBottom>
        Payment Voucher
      </Typography>

      <Box sx={{ marginBottom: 2 }}>
        <Typography variant="body1"><strong>Pay To:</strong> {paymentData.requestor}</Typography>
        <Typography variant="body1"><strong>Amount:</strong> RM {formatNumberWithCommas(paymentData.commission)}</Typography>
        <Typography variant="body1"><strong>Description:</strong> {paymentData.type} Commission Payment</Typography>
        <Typography variant="body1"><strong>Date:</strong> {paymentData.requestedAt}</Typography>
      </Box>

      <Button variant="contained" color="primary" onClick={handleDownloadPDF}>
        Download PDF
      </Button>
    </Box>
  );
};

export default ViewCommProject;
