import React, { useState, useEffect } from "react";
import TableComponent from "../../components/TableComponent";
import {
  IconButton,
  Typography,
  Button,
  Modal,
  Box,
  TextField,
  CircularProgress,
} from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import DeleteIcon from "@mui/icons-material/Delete";
import { useAuth } from "../../context/AuthContext";
import { db } from "../../firebase";
import {
  addDoc,
  collection,
  getDocs,
  doc,
  deleteDoc,
} from "firebase/firestore";
import * as XLSX from "xlsx";

const ContactsPage = () => {
  const [contacts, setContacts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const [bulkModalOpen, setBulkModalOpen] = useState(false);
  const [saving, setSaving] = useState(false);
  const [clientName, setClientName] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [source, setSource] = useState("");
  const { currentUser } = useAuth();

  const columns = [
    { id: "no", label: "No.", minWidth: 50, align: "center" },
    { id: "createdAt", label: "Date", minWidth: 150, align: "center" },
    { id: "clientName", label: "Client Name", minWidth: 200, align: "left" },
    { id: "contactNumber", label: "Contact Number", minWidth: 200, align: "center" },
    { id: "source", label: "Source", minWidth: 150, align: "center" },
    // {
    //   id: "action",
    //   label: "Action",
    //   minWidth: 150,
    //   align: "center",
    //   render: (row) => (
    //     <>
    //       <IconButton>
    //         <DownloadIcon />
    //       </IconButton>
    //       <IconButton onClick={() => handleDelete(row.id)}>
    //         <DeleteIcon />
    //       </IconButton>
    //     </>
    //   ),
    // },
  ];

  useEffect(() => {
    const fetchContacts = async () => {
      setLoading(true);
      try {
        const querySnapshot = await getDocs(collection(db, "contacts"));
        const fetchedContacts = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        setContacts(fetchedContacts);
      } catch (error) {
        console.error("Error fetching contacts:", error);
        alert("Failed to fetch contacts. Please try again.");
      } finally {
        setLoading(false);
      }
    };

    fetchContacts();
  }, []);

  const handleSaveContact = async () => {
    if (!clientName || !contactNumber || !source) {
      alert("Please fill all fields.");
      return;
    }

    setSaving(true);

    try {
      const contactData = {
        clientName,
        contactNumber,
        source,
        createdBy: currentUser.uid,
        createdAt: new Date().toLocaleDateString(),
      };

      const docRef = await addDoc(collection(db, "contacts"), contactData);
      setContacts((prev) => [...prev, { id: docRef.id, ...contactData }]);
      setModalOpen(false);
      setClientName("");
      setContactNumber("");
      setSource("");
    } catch (error) {
      console.error("Error saving contact:", error);
      alert("Failed to save contact. Please try again.");
    } finally {
      setSaving(false);
    }
  };

  const handleDelete = async (id) => {
    try {
      await deleteDoc(doc(db, "contacts", id));
      setContacts((prev) => prev.filter((contact) => contact.id !== id));
    } catch (error) {
      console.error("Error deleting contact:", error);
      alert("Failed to delete contact. Please try again.");
    }
  };

  const handleBulkUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    try {
      const data = await file.arrayBuffer();
      const workbook = XLSX.read(data, { type: "array" });
      const sheet = workbook.Sheets[workbook.SheetNames[0]];
      const rows = XLSX.utils.sheet_to_json(sheet);

      const bulkContacts = rows.map((row) => ({
        clientName: row["Client Name"],
        contactNumber: row["Contact Number"],
        source: row["Source"],
        createdAt: new Date().toLocaleDateString(),
        createdBy: currentUser.uid,
      }));

      const batchPromises = bulkContacts.map((contact) =>
        addDoc(collection(db, "contacts"), contact)
      );

      await Promise.all(batchPromises);
      setContacts((prev) => [...prev, ...bulkContacts]);
      alert("Bulk upload successful.");
      setBulkModalOpen(false);
    } catch (error) {
      console.error("Error in bulk upload:", error);
      alert("Failed to upload contacts. Please check the file format.");
    }
  };

  const downloadTemplate = () => {
    const templateData = [
      { "Client Name": "", "Contact Number": "", "Source": "" },
    ];

    const worksheet = XLSX.utils.json_to_sheet(templateData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Template");

    XLSX.writeFile(workbook, "ContactsTemplate.xlsx");
  };

  return (
    <div>
      <Box>
        <Typography
          variant="h6"
          gutterBottom
          sx={{
            padding: 2,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          Contacts
          <Box>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setModalOpen(true)}
              sx={{ marginRight: 2 }}
            >
              Add Contact
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => setBulkModalOpen(true)}
            >
              Bulk Upload
            </Button>
          </Box>
        </Typography>
      </Box>
      <TableComponent columns={columns} data={contacts} loading={loading} />

      {/* Add Contact Modal */}
      <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "100%",
            maxWidth: "800px",
            bgcolor: "background.paper",
            p: 4,
          }}
        >
          <Typography variant="h6" sx={{ mb: 2 }}>
            Add Contact
          </Typography>
          <TextField
            fullWidth
            label="Client Name"
            margin="normal"
            value={clientName}
            onChange={(e) => setClientName(e.target.value)}
          />
          <TextField
            fullWidth
            label="Contact Number"
            margin="normal"
            value={contactNumber}
            onChange={(e) => setContactNumber(e.target.value)}
          />
          <TextField
            fullWidth
            label="Source"
            margin="normal"
            value={source}
            onChange={(e) => setSource(e.target.value)}
          />
          <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
            <Button onClick={() => setModalOpen(false)} sx={{ mr: 1 }}>
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSaveContact}
              disabled={saving}
            >
              {saving ? <CircularProgress size={24} /> : "Save"}
            </Button>
          </Box>
        </Box>
      </Modal>

      {/* Bulk Upload Modal */}
      <Modal open={bulkModalOpen} onClose={() => setBulkModalOpen(false)}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "100%",
            maxWidth: "800px",
            display: "flex",
            flexDirection: "column",
            bgcolor: "background.paper",
            p: 4,
          }}
        >
          <Typography variant="h6" sx={{ mb: 2 }}>
            Bulk Upload
          </Typography>
          <Button
            onClick={downloadTemplate}
            sx={{
              textTransform: "none",
              color: "inherit",
              background: "none",
              mb: 2,
              padding: 0,
              minWidth: 0,
              textAlign: 'left'
            }}
          >
            Download Template
          </Button>
          <Button variant="contained" component="label">
            Upload Excel File
            <input
              type="file"
              accept=".xlsx, .xls"
              hidden
              onChange={handleBulkUpload}
            />
          </Button>
        </Box>
      </Modal>
    </div>
  );
};

export default ContactsPage;