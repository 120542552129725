import React, { useState, useEffect } from "react";
import TableComponent from "../../components/TableComponent";
import {
  IconButton,
  Typography,
  Button,
  Modal,
  Box,
  TextField,
  CircularProgress,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DownloadIcon from "@mui/icons-material/Download";
import DeleteIcon from "@mui/icons-material/Delete";
import { useAuth } from "../../context/AuthContext";
import { db, storage } from "../../firebase";
import {
  addDoc,
  collection,
  getDocs,
  doc,
  updateDoc
} from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { useNavigate } from "react-router-dom";

const DocumentsPage = () => {
  const [documents, setDocuments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [targetDocumentId, setTargetDocumentId] = useState(null);
  const [title, setTitle] = useState("");
  const [file, setFile] = useState(null);
  const [saving, setSaving] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const navigate = useNavigate();
  const { userData, currentUser } = useAuth();

  const columns = [
    { id: "no", label: "No.", minWidth: 50, align: "center" },
    { id: "title", label: "Title", minWidth: 200, align: "left" },
    {
      id: "action",
      label: "Action",
      minWidth: 150,
      align: "center",
      render: (row) => (
        <>
          <IconButton
            component="a"
            href={row.documentUrl}
            download
            target="_blank"
            rel="noopener noreferrer"
          >
            <DownloadIcon />
          </IconButton>
          {userData?.position === "Admin" && ( // Render Delete Icon for Admin Only
            <IconButton
              onClick={() => openConfirmationModal(row.id)} // Open the confirmation modal
            >
              <DeleteIcon />
            </IconButton>
          )}
        </>
      ),
    },
  ];

  useEffect(() => {
    const fetchAnnouncements = async () => {
      setLoading(true);
      try {
        // Reference the announcements collection
        const querySnapshot = await getDocs(collection(db, "official_documents"));

        // Map over the documents and add them to the state
        const fetchedAnnouncements = querySnapshot.docs
          .map((doc) => ({
            id: doc.id, // Include the document ID
            ...doc.data(), // Spread the document data
          }))
          .sort((a, b) => b.createdAt.seconds - a.createdAt.seconds);

        setDocuments(fetchedAnnouncements);
      } catch (error) {
        console.error("Error fetching announcements:", error);
        alert("Failed to fetch announcements. Please try again.");
      } finally {
        setLoading(false);
      }
    };

    fetchAnnouncements();
  }, []);

  const handleFileUpload = (event) => {
    setFile(event.target.files[0]);
  };

  const handleSaveAnnouncement = async () => {
    if (!title || !file) {
      alert("Please fill all fields and upload a file.");
      return;
    }

    setSaving(true);

    try {
      // Upload the file to Firebase Storage
      const fileRef = ref(storage, `official_documents/${file.name}`);
      await uploadBytes(fileRef, file);
      const documentUrl = await getDownloadURL(fileRef);

      // Save announcement to Firestore
      const announcementData = {
        title,
        documentUrl,
        createdAt: new Date(),
        createdBy: currentUser.uid,
        deletedAt: null,
        deletedBy: null,
        isDeleted: 0,
      };

      await addDoc(collection(db, "official_documents"), announcementData);

      // Add to local state
      setDocuments((prev) => [...prev, announcementData]);

      // Reset fields and close modal
      setTitle("");
      setFile(null);
      setModalOpen(false);
    } catch (error) {
      console.error("Error saving announcement:", error);
      alert("Failed to save announcement. Please try again.");
    } finally {
      setSaving(false);
    }
  };

  const openConfirmationModal = (announcementId) => {
    setTargetDocumentId(announcementId);
    setConfirmationModalOpen(true);
  };

  const handleDeleteConfirmation = async () => {
    if (!targetDocumentId) return;

    try {
      // Reference the document
      const announcementDoc = doc(db, "official_documents", targetDocumentId);

      // Update the isDeleted field and add deletion metadata
      await updateDoc(announcementDoc, {
        isDeleted: 1,
        deletedAt: new Date(),
        deletedBy: currentUser.uid,
      });

      // Update the local state to reflect the change
      setDocuments((prev) =>
        prev.map((item) =>
          item.id === targetDocumentId
            ? { ...item, isDeleted: 1, deletedAt: new Date(), deletedBy: currentUser.uid }
            : item
        )
      );

      alert("Announcement deleted successfully.");
    } catch (error) {
      console.error("Error deleting announcement:", error);
      alert("Failed to delete announcement. Please try again.");
    } finally {
      setConfirmationModalOpen(false);
      setTargetDocumentId(null);
    }
  };

  const handleCancelConfirmation = () => {
    setConfirmationModalOpen(false);
    setTargetDocumentId(null);
  };

  const filteredAnnouncements = documents.filter(
    (item) =>
      item.isDeleted === 0 &&
      item.title.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div>
      <Box>
        <Typography
          variant="h6"
          gutterBottom
          sx={{
            padding: 2,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          Official Documents
          {userData?.position === "Admin" && (
            <Button
              variant="contained"
              color="primary"
              onClick={() => setModalOpen(true)}
            >
              Add Document
            </Button>
          )}
        </Typography>
      </Box>
      <TextField
          placeholder="Search..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          sx={{
            padding: "10px", // Adjust padding around the TextField
            ".MuiInputBase-root": {
              height: "40px", // Set a shorter height for the input field
            },
          }}
        />
      <TableComponent
        columns={columns}
        data={filteredAnnouncements} // Filter announcements
        loading={loading}
      />

      <Modal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        aria-labelledby="add-announcement-modal"
        aria-describedby="add-announcement-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "100%",
            maxWidth: "800px", // Set maximum width to avoid excessive stretching
            height: "auto", // Adjust height based on content
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography variant="h6" id="add-announcement-modal" sx={{ mb: 2 }}>
            Add Announcement
          </Typography>
          <TextField
            fullWidth
            label="Title"
            margin="normal"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
          <Button variant="contained" component="label" sx={{ mt: 2 }}>
            Upload File
            <input
              type="file"
              hidden
              // accept=".pdf,.doc,.docx"
              onChange={handleFileUpload}
            />
          </Button>
          {file && <Typography sx={{ mt: 1 }}>{file.name}</Typography>}
          <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => setModalOpen(false)}
              sx={{ mr: 1 }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSaveAnnouncement}
              disabled={saving}
            >
              {saving ? <CircularProgress size={24} /> : "Save"}
            </Button>
          </Box>
        </Box>
      </Modal>

      {/* Confirmation Modal */}
      <Modal
        open={confirmationModalOpen}
        onClose={handleCancelConfirmation}
        aria-labelledby="delete-confirmation-modal"
        aria-describedby="delete-confirmation-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography variant="h6" id="delete-confirmation-modal" sx={{ mb: 2 }}>
            Confirm Deletion
          </Typography>
          <Typography>
            Are you sure you want to delete this announcement?
          </Typography>
          <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
            <Button
              variant="outlined"
              color="primary"
              onClick={handleCancelConfirmation}
              sx={{ mr: 1 }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="error"
              onClick={handleDeleteConfirmation}
            >
              Confirm
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default DocumentsPage;
