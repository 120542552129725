import { useState, useEffect } from 'react';
import { Box, IconButton, Typography, Button, Paper, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@mui/material';
import TableComponent from '../../components/TableComponent'; // Your reusable table component
import VisibilityIcon from '@mui/icons-material/Visibility'; // View icon
import EditIcon from '@mui/icons-material/Edit'; // Edit icon
import DeleteIcon from '@mui/icons-material/Delete'; // Delete icon
import { useNavigate } from 'react-router-dom';
import { collection, getDocs, deleteDoc, doc } from 'firebase/firestore';
import { db } from '../../firebase'; // Your firebase config file
import { useAuth } from '../../context/AuthContext';
import { StatusWrapper } from '../../components/StatusWrapper';

const Subsale = () => {
  const navigate = useNavigate();
  const { currentUser, userData } = useAuth(); 
  const [subsaleData, setSubsaleData] = useState([]);
  const [filteredData, setFilteredData] = useState([]); // For filtered search results
  const [searchQuery, setSearchQuery] = useState(''); // For search input
  const [loading, setLoading] = useState(true); // Loading state
  const [open, setOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  useEffect(() => {
    // Fetch data from Firebase Firestore
    const fetchSubsaleData = async () => {
      // Fetch statuses from the "Status" collection
      const statusSnapshot = await getDocs(collection(db, 'status'));
      const statusMap = statusSnapshot.docs.reduce((acc, doc) => {
        acc[doc.id] = doc.data().Name; // Map project ID to project name
        return acc;
      }, {});

      setLoading(true); // Set loading to true at the start of data fetching
      try {
        const querySnapshot = await getDocs(collection(db, 'subsaleDeals'));
        const subsales = querySnapshot.docs.map((doc, index) => ({
          no: index + 1,
          id: doc.id,
          subsaleDealId: doc.subsaleDealId,
          purchaserName: doc.purchaserName,
          project: doc.data().projectName,
          lotNumber: doc.data().lotNumber,
          status: doc.data().status,
          status1: statusMap[doc.data().status] || 'Unknown Status', // Replace or calculate status as needed
          ...doc.data(),
        }));
        if (userData.position === 'Admin') {
          setSubsaleData(subsales);
          setFilteredData(subsales); // Set filtered data to initial data
        } else {
          const userProjects = subsales
            .filter((subsale) => subsale.createdBy === currentUser.uid)
            .sort((a, b) => a.subsaleDealId.localeCompare(b.subsaleDealId)); // Sort by projectDealId
          setSubsaleData(userProjects);
          setFilteredData(userProjects); // Set filtered data to user-specific data
        }
      } catch (error) {
        console.error('Error fetching subsale data:', error);
      } finally {
        setLoading(false); // Set loading to false once data is fetched
      }
    };

    fetchSubsaleData();
  }, [currentUser, userData.position]);

  // Handle search input change
  const handleSearchChange = (event) => {
    const query = event.target.value.toLowerCase();
    setSearchQuery(query);
    const filtered = subsaleData.filter(
      (item) =>
        item.subsaleDealId.toLowerCase().includes(query) ||
        item.purchaserName.toLowerCase().includes(query) ||
        item.project.toLowerCase().includes(query)
    );
    setFilteredData(filtered);
  };

  // Define columns for the table
  const columns = [
    { id: 'no', label: 'No.', minWidth: 50 },
    { id: 'subsaleDealId', label: 'Subsales Order ID', minWidth: 150 },
    { id: 'purchaserName', label: 'Purchaser Name', minWidth: 150 },
    { id: 'project', label: 'Project Name', minWidth: 150 },
    { id: 'lotNumber', label: 'Lot Number', minWidth: 150 },
    { id: 'purchasePrice', label: 'Purchase Price', minWidth: 150 },
    {
      id: 'status1',
      label: 'Status',
      minWidth: 150,
      render: (row) => StatusWrapper(row.status, row.status1),
    },
    {
      id: 'action',
      label: 'Action',
      minWidth: 150,
      render: (row) => (
        <Box>
          <IconButton color="primary" onClick={() => handleView(row)}>
            <VisibilityIcon />
          </IconButton>
          <IconButton color="secondary" onClick={() => handleEdit(row)}>
            <EditIcon />
          </IconButton>
          <IconButton color="error" onClick={() => handleDeleteClick(row)}>
            <DeleteIcon />
          </IconButton>
        </Box>
      ),
    },
  ];

  // Handlers for the action buttons
  const handleView = (row) => {
    navigate(`/sales/view-subsale-deal/${row.id}`);
  };

  const handleEdit = (row) => {
    navigate(`/sales/edit-subsale-deal/${row.id}`);
  };

  const handleDelete = async () => {
    if (selectedRow) {
      try {
        // Delete the document from Firestore
        await deleteDoc(doc(db, 'subsaleDeals', selectedRow.id));
        // Update the local state to remove the deleted item
        setSubsaleData((prevData) => prevData.filter((item) => item.id !== selectedRow.id));
        setFilteredData((prevData) => prevData.filter((item) => item.id !== selectedRow.id));
        setOpen(false); // Close the confirmation dialog
        setSelectedRow(null); // Clear the selected row
      } catch (error) {
        console.error('Error deleting document:', error);
      }
    }
  };

  const handleDeleteClick = (row) => {
    setSelectedRow(row);
    setOpen(true); // Open the confirmation dialog
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedRow(null);
  };

  const handleAddNewDeal = () => {
    navigate('/sales/create-subsale-deal');
  };

  return (
    <Paper>
      <Typography
        variant="h6"
        gutterBottom
        sx={{
          padding: 2,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        Subsale Deal List
        <Button variant="contained" color="primary" onClick={handleAddNewDeal}>
          Add New
        </Button>
      </Typography>

      {/* Search Input */}
      <TextField
        placeholder="Search..."
        value={searchQuery}
        onChange={handleSearchChange}
        sx={{
          width: '250px',
          padding: '10px',
          marginBottom: '10px',
          '.MuiInputBase-root': {
            height: '40px', // Set a shorter height for the input field
          },
        }}
      />

      <TableComponent columns={columns} data={filteredData} loading={loading} />

      {/* Confirmation Dialog */}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this subsale deal? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDelete} color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
};

export default Subsale;
