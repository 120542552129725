import React, { useEffect, useState } from 'react';
import { Box, Grid, Typography, Button, Avatar, TextField, CardContent, Card, Snackbar, Alert, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import { doc, getDoc, getDocs, collection, updateDoc } from 'firebase/firestore';
import { db } from '../../firebase';

const ViewRequestRental = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [rentalData, setRentalData] = useState(null);
  const [agentNames, setAgentNames] = useState({});
  const [openModal, setOpenModal] = useState(false); // Modal state for comments
  const [comments, setComments] = useState(''); // State for admin comments

  useEffect(() => {
    const fetchRentalData = async () => {
      try {
        const docRef = doc(db, 'rentalDeals', id);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setRentalData(docSnap.data());
        } else {
          console.error('No such document!');
        }
      } catch (error) {
        console.error('Error fetching rental data:', error);
      }
    };

    const fetchAgentNames = async () => {
      try {
        const usersCollection = collection(db, 'users');
        const querySnapshot = await getDocs(usersCollection);
        const names = {};
        querySnapshot.forEach((doc) => {
          const data = doc.data();
          names[doc.id] = data.name;
        });
        setAgentNames(names);
      } catch (error) {
        console.error('Error fetching agent names:', error);
      }
    };

    fetchRentalData();
    fetchAgentNames();
  }, [id]);

  if (!rentalData) {
    return <Typography>Loading...</Typography>;
  }

  const handleBackButton = () => {
    navigate('/requests', { state: { tabIndex: 0 } });
  };

  const handleStatusUpdate = async (newStatus, adminComments = '') => {
    try {
      const docRef = doc(db, 'rentalDeals', id);
      await updateDoc(docRef, { status: newStatus, comments: adminComments });
      setRentalData((prevData) => ({ ...prevData, status: newStatus, comments: adminComments }));
      alert(`Status updated to ${newStatus === 7 ? 'Converted' : 'Returned'} with comments.`);
      navigate('/requests', { state: { tabIndex: 0 } });
    } catch (error) {
      console.error('Error updating status:', error);
      alert('Failed to update status. Please try again.');
    }
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setComments(''); // Reset comments
  };

  const handleSubmitReturn = () => {
    handleStatusUpdate(6, comments); // Update status to Returned (6) with comments
    handleCloseModal(); // Close the modal
  };

  const renderDocumentField = (label, url) => (
    <TextField
      label={label}
      variant="outlined"
      fullWidth
      value={url ? 'View Document' : 'N/A'}
      InputProps={{
        readOnly: true,
        endAdornment: url && (
          <Button variant="text" href={url} target="_blank">
            View
          </Button>
        ),
      }}
      sx={{ mb: 2 }}
    />
  );

  return (
    <Box sx={{ p: 3, border: '1px solid #ddd', borderRadius: '8px', backgroundColor: '#fff' }}>
      <Button
        variant="contained"
        color="primary"
        onClick={handleBackButton}
        sx={{ mb: 2, marginBottom: '50px' }}
      >
        Back
      </Button>
      <Typography variant="h6" gutterBottom sx={{ mb: 2, marginBottom: '20px' }}>
        Rental Deal Information
      </Typography>
      <Grid container spacing={2}>
        {/* Left Column */}
        <Grid item xs={12} sm={4}>
          <TextField
            label="Landlord Name"
            variant="outlined"
            fullWidth
            value={rentalData.landlordName || 'N/A'}
            InputProps={{ readOnly: true }}
          />
          <TextField
            label="Tenant Name"
            variant="outlined"
            fullWidth
            value={rentalData.tenantName || 'N/A'}
            InputProps={{ readOnly: true }}
            sx={{ mt: 2 }}
          />
          <TextField
            label="Project Name"
            variant="outlined"
            fullWidth
            value={rentalData.projectName || 'N/A'}
            InputProps={{ readOnly: true }}
            sx={{ mt: 2 }}
          />
          <TextField
            label="Lot Number"
            variant="outlined"
            fullWidth
            value={rentalData.lotNumber || 'N/A'}
            InputProps={{ readOnly: true }}
            sx={{ mt: 2 }}
          />
        </Grid>

        {/* Middle Column */}
        <Grid item xs={12} sm={4}>
          <TextField
            label="Rental Per Month (RM)"
            variant="outlined"
            fullWidth
            value={rentalData.rentalPerMonth || 'N/A'}
            InputProps={{ readOnly: true }}
          />
          <TextField
            label="Total Rental Deposit (RM)"
            variant="outlined"
            fullWidth
            value={rentalData.totalRentalDeposit || 'N/A'}
            InputProps={{ readOnly: true }}
            sx={{ mt: 2 }}
          />
          <TextField
            label="Security Deposit (RM)"
            variant="outlined"
            fullWidth
            value={rentalData.securityDeposit || 'N/A'}
            InputProps={{ readOnly: true }}
            sx={{ mt: 2 }}
          />
          <TextField
            label="Access Card Deposit (RM)"
            variant="outlined"
            fullWidth
            value={rentalData.accessCardDeposit || 'N/A'}
            InputProps={{ readOnly: true }}
            sx={{ mt: 2 }}
          />
          <TextField
            label="Stamping Fees (RM)"
            variant="outlined"
            fullWidth
            value={rentalData.stampingFees || 'N/A'}
            InputProps={{ readOnly: true }}
            sx={{ mt: 2 }}
          />
        </Grid>

        {/* Right Column */}
        <Grid item xs={12} sm={4}>
          {renderDocumentField('Rental Form', rentalData.rentalFormUploaded)}
          {renderDocumentField('Transaction Slip 1', rentalData.transactionSlip1Uploaded)}
          {renderDocumentField('Transaction Slip 2', rentalData.transactionSlip2Uploaded)}
          {renderDocumentField('Tenancy Agreement', rentalData.tenancyAgreementUploaded)}
          {renderDocumentField('Stamping Certificate', rentalData.stampingCertUploaded)}
        </Grid>
      </Grid>

      {/* Agents Information */}
      <Card sx={{ mt: 4 }}>
        <CardContent>
          <Typography variant="h6" gutterBottom>
            Agents Information
          </Typography>
          {rentalData.salesAgentShare.map((agent, index) => (
            <Box key={index} display="flex" alignItems="center" sx={{ mb: 2 }}>
              <Avatar sx={{ bgcolor: '#3f51b5', mr: 2 }}>
                {agentNames[agent.uid]?.[0] || 'A'}
              </Avatar>
              <Box>
                <Typography>
                  Sales Agent: {agentNames[agent.uid] || agent.uid}
                </Typography>
                <Typography>
                  Commission Share: {agent.sharePercentage}%
                </Typography>
              </Box>
            </Box>
          ))}
          <TextField
            label="Remarks"
            variant="outlined"
            fullWidth
            value={rentalData.remark || 'N/A'}
            InputProps={{ readOnly: true }}
            sx={{ mt: 2 }}
          />
        </CardContent>
      </Card>

      {/* Status and Action Buttons */}
      <Box display="flex" justifyContent="flex-end" gap={2} mt={4}>
        <Button
          variant="contained"
          color="success"
          onClick={() => handleStatusUpdate(7)}
        >
          Convert
        </Button>
        <Button
          variant="contained"
          color="error"
          onClick={handleOpenModal}
        >
          Return
        </Button>
      </Box>

      {/* Comments Modal */}
      <Dialog
        open={openModal}
        onClose={handleCloseModal}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>Provide Comments for Return</DialogTitle>
        <DialogContent>
          <TextField
            label="Comments"
            variant="outlined"
            fullWidth
            multiline
            rows={4}
            value={comments}
            onChange={(e) => setComments(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSubmitReturn} color="error">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ViewRequestRental;
