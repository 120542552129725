import React, { useState, useEffect } from 'react';
import { Box, Button, Stepper, Step, StepLabel, TextField, Typography, Select, MenuItem, FormControl, InputLabel, useMediaQuery, Snackbar, Alert } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { collection, getDocs, addDoc } from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { db } from '../../../firebase'; // Your Firebase config file
import { formatNumberWithCommas } from '../../../Helper/Helper';
import useAuth from '../../../hooks/useAuth';
import { fetchSalesAgents } from '../../../Helper/FirebaseHelper';

const steps = [
  'Basic Information',
  'SPA & Pricing Information',
  'Loan & Referral Information',
  'Documents',
  'Sales Agent & Remarks'
];

const agreementStatus = [
  {
    id: 1,
    status: 'Pending',
  },
  {
    id: 2,
    status: 'Signed',
  },
];

const CreateProjectDealPage = () => {
  const theme = useTheme();
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();
  const [sources, setSources] = useState([]);
  const [projects, setProjects] = useState([]); // State to store the projects
  const [salesAgents, setSalesAgents] = useState([]);
  const [activeStep, setActiveStep] = useState(0);
  const { user } = useAuth(); 
  const storage = getStorage();
  const [isRebatePercentage, setIsRebatePercentage] = useState(false); // Default to RM
  const [formData, setFormData] = useState({
    projectDealId: '',
    lotNumber: '',
    bookingDate: '',
    projectId: '',
    purchaserName: '',
    spaPrice: '',
    nettPrice: '',
    rebate: '',
    spaDate: '',
    status: '',
    source: '',
    referralName: '',
    coAgency: '',
    loanMargin: '',
    loLaSignDate: '',
    loLaStatus: '',
    bookingFormUrl: '',
    spaCopyUrl: '',
    loLaSignCopyUrl: '',
    cancellationFormUrl: '',
    salesAgentShare: [{ uid: 'user-uid', sharePercentage: '100' }],
    remark: '',
    createdBy: '',
    createdAt: '',
    updatedBy: '',
    updatedAt: '',
    comments: '',
  });

  // Fetch projects from Firebase
  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, 'projects'));
        const projectsList = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data()
        }));
        projectsList.sort((a, b) => a.name.localeCompare(b.name));
        setProjects(projectsList);
      } catch (error) {
        console.error('Error fetching projects:', error);
      }
    };

    const fetchSources = async () => {
      try{
        const querySnapshot = await getDocs(collection(db, 'sources'));
        const sourcesList = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data()
        }));
        sourcesList.sort((a, b) => a.name.localeCompare(b.name));
        setSources(sourcesList);
      }catch(err){
        console.error('Error fetching sources:', err);
      }
    }

    const getSalesAgents = async () => {
      const agents = await fetchSalesAgents();
      setSalesAgents(agents);
    };

    getSalesAgents();

    // Set the current user as the initial sales agent
    if (user) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        salesAgentShare: [{ uid: user.uid, sharePercentage: '100' }],
      }));
    }

    fetchSources();
    fetchProjects();
  }, [user]);

  const handleNext = () => {
    if (!isStepValid()) return;
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleChange = async (event) => {
    const { name, value, type, files } = event.target;
  
    if (files) {
      // Handle file upload to Firebase Storage
      const file = files[0];
      if (file) {
        const fileRef = ref(storage, `documents/${file.name}`);
        await uploadBytes(fileRef, file);
        const fileUrl = await getDownloadURL(fileRef);
  
        setFormData({
          ...formData,
          [name]: fileUrl,
        });
      }
    } else if (['spaPrice', 'nettPrice', 'rebate'].includes(name)) {
      const numericValue = value.replace(/,/g, ''); // Remove commas before parsing
      if (!isNaN(numericValue)) {
        setFormData({
          ...formData,
          [name]: formatNumberWithCommas(numericValue),
        });
      }
    } else {
      setFormData({
        ...formData,
        [name]: type === 'checkbox' ? event.target.checked : value,
      });
    }
  };

  const saveToFirebase = async (status) => {
    try {
      // Fetch the length of the projectDeals collection
      const querySnapshot = await getDocs(collection(db, 'projectDeals'));
      const dealCount = querySnapshot.size; // Get the total count of projectDeals
  
      // Generate a unique projectDealId based on the count
      const newProjectDealId = `PD-${dealCount + 1}`; // For example, "PD-101"
  
      const newFormData = {
        ...formData,
        projectDealId: newProjectDealId, // Add the generated ID to the formData
        status,
        createdBy: user ? user.uid : 'unknown',
        createdAt: new Date().toISOString(),
        updatedBy: user ? user.uid : 'unknown',
        updatedAt: new Date().toISOString(),
        rebate: {
          value: formData.rebate,
          type: formData.isRebatePercentage ? '%' : 'RM', // Save input type (RM or %)
        },
      };
  
      // Save the new project deal to Firestore
      await addDoc(collection(db, 'projectDeals'), newFormData);
  
      setSnackbarMessage(status === 'Pending' ? 'Submitted successfully!' : 'Draft saved successfully!');
      setOpenSnackbar(true);
      navigate('/sales', { state: { tabIndex: 0 } });
    } catch (error) {
      console.error('Error saving data:', error);
    }
  };

  const handleSubmit = () => {
    if (!isStepValid()) return;
    saveToFirebase(3); //Ready To Convert
    navigate('/sales'); // Redirect after submission
  };
  
  const handleDraft = () => {
    saveToFirebase(1); //Drafted
    navigate('/sales'); // Redirect after submission
  };

  const handleAgentChange = (index, field, value) => {
    const updatedAgents = [...formData.salesAgentShare];
    updatedAgents[index][field] = value;
    setFormData({ ...formData, salesAgentShare: updatedAgents });
  };

  const addAgent = () => {
    setFormData({
      ...formData,
      salesAgentShare: [...formData.salesAgentShare, { uid: '', sharePercentage: '' }],
    });
  };

  const isStepValid = () => {
    // Validation logic for each step
    if (activeStep === 0) {
      return formData.lotNumber && formData.bookingDate && formData.projectId && formData.purchaserName;
    }
    if (activeStep === 1) {
      return formData.spaPrice && formData.nettPrice && formData.rebate;
    }
    if (activeStep === 2) {
      return formData.source;
    }
    if (activeStep === 3) {
      return formData.bookingFormUrl;
    }
    if (activeStep === 4) {
      return formData.salesAgentShare.every(agent => agent.uid && agent.sharePercentage);
    }
    return true;
  };

  const renderStepContent = (step) => {
    switch (step) {
      case 0: // Basic Information
        return (
          <Box display="flex" flexDirection="column" alignItems="left" sx={{ border: '1px solid #ddd', borderRadius: '8px', padding: '16px', backgroundColor: '#f9f9f9' }}>
            <Typography variant="h6">Basic Information</Typography>
            <TextField 
              fullWidth 
              label="Purchaser Name *" 
              name="purchaserName" 
              value={formData.purchaserName} 
              onChange={handleChange} 
              margin="normal" 
            />
            <FormControl fullWidth margin="normal">
              <InputLabel>Project *</InputLabel>
              <Select name="projectId" value={formData.projectId} onChange={handleChange} label="Project">
                {projects.map((project) => (
                  <MenuItem key={project.id} value={project.id}>{project.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField fullWidth label="Lot Number *" name="lotNumber" value={formData.lotNumber} onChange={handleChange} margin="normal" />
            <TextField 
              sx={{ width: isMobile ? '100%' : '200px' }}
              label="Booking Date *" 
              name="bookingDate" 
              type="date" 
              InputLabelProps={{ shrink: true }} 
              value={formData.bookingDate} 
              onChange={handleChange} margin="normal"
            />
          </Box>
        );
  
        case 1: // SPA & Pricing Information
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="left"
      sx={{ border: "1px solid #ddd", borderRadius: "8px", padding: "16px", backgroundColor: "#f9f9f9" }}
    >
      <Typography variant="h6">SPA & Pricing Information</Typography>
      <TextField
        fullWidth
        label="SPA Price *"
        name="spaPrice"
        value={formData.spaPrice}
        onChange={handleChange}
        margin="normal"
      />
      <TextField
        fullWidth
        label="Nett Price *"
        name="nettPrice"
        value={formData.nettPrice}
        onChange={handleChange}
        margin="normal"
      />

      {/* Toggle for Rebate Input Mode */}
      <Box display="flex" alignItems="center" mt={2}>
        <Typography variant="body2" sx={{ mr: 2 }}>Rebate in</Typography>
        <Box sx={{ display: "flex", gap: 1 }}>
          <Button
            variant={!formData.isRebatePercentage ? "contained" : "outlined"}
            onClick={() =>
              setFormData({ ...formData, isRebatePercentage: false, rebate: "" }) // Clear rebate on toggle
            }
          >
            RM
          </Button>
          <Button
            variant={formData.isRebatePercentage ? "contained" : "outlined"}
            onClick={() =>
              setFormData({ ...formData, isRebatePercentage: true, rebate: "" }) // Clear rebate on toggle
            }
          >
            %
          </Button>
        </Box>
      </Box>

      {/* Rebate Input Field */}
      <TextField
        fullWidth
        label={`Rebate * (${formData.isRebatePercentage ? "%" : "RM"})`}
        name="rebate"
        value={formData.rebate}
        onChange={(event) => {
          const value = event.target.value;
          if (value === "") {
            setFormData({ ...formData, rebate: "" });
          } else if (formData.isRebatePercentage) {
            // Validate percentage input
            const percentageValue = parseFloat(value);
            if (!isNaN(percentageValue) && percentageValue >= 0 && percentageValue <= 100) {
              setFormData({ ...formData, rebate: value });
            }
          } else {
            // Allow any value in RM mode
            const numericValue = value.replace(/[^0-9.]/g, ""); // Remove non-numeric characters
            setFormData({ ...formData, rebate: formatNumberWithCommas(numericValue) });
          }
        }}
        type="text" // Allow flexible input
        margin="normal"
        inputProps={{
          ...(formData.isRebatePercentage ? { maxLength: 5 } : {}), // Limit input length for %
        }}
      />

      <TextField
        sx={{ width: isMobile ? "100%" : "200px" }}
        label="SPA Date"
        name="spaDate"
        type="date"
        InputLabelProps={{ shrink: true }}
        value={formData.spaDate}
        onChange={handleChange}
        margin="normal"
      />
    </Box>
  );

      
      
  
      case 2: // Loan & Referral Information
        return (
          <Box display="flex" flexDirection="column" alignItems="left" sx={{ border: '1px solid #ddd', borderRadius: '8px', padding: '16px', backgroundColor: '#f9f9f9' }}>
            <Typography variant="h6">Loan & Referral Information</Typography>
            <FormControl fullWidth margin="normal">
              <InputLabel>Source *</InputLabel>
              <Select name="source" value={formData.source} onChange={handleChange} label="Source">
                {sources.map((source) => (
                  <MenuItem key={source.id} value={source.name}>{source.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField fullWidth label="Referral Name" name="referralName" value={formData.referralName} onChange={handleChange} margin="normal" />
            <TextField fullWidth label="Co-Agency" name="coAgency" value={formData.coAgency} onChange={handleChange} margin="normal" />
            <TextField 
              fullWidth 
              label="Loan Margin" 
              name="loanMargin" 
              value={formData.loanMargin} 
              onChange={(event) => {
                // Ensure the value is between 0 and 100
                const numericValue = parseFloat(event.target.value);
                if (!isNaN(numericValue) && numericValue >= 0 && numericValue <= 100) {
                  setFormData({ ...formData, loanMargin: event.target.value });
                } else if (event.target.value === '') { // Allow clearing the input
                  setFormData({ ...formData, loanMargin: '' });
                }
              }}
              type="number"
              inputProps={{
                min: 0,
                max: 100,
                step: 0.01 // Allows decimal values, adjust as needed
              }}
              margin="normal" 
            />
            <FormControl fullWidth margin="normal">
              <InputLabel>LO/LA Status</InputLabel>
              <Select name="loLaStatus" value={formData.loLaStatus} onChange={handleChange} label="LO/LA Status">
                {agreementStatus.map((status) => (
                  <MenuItem key={status.id} value={status.status}>{status.status}</MenuItem> // Use status.status for the displayed value
                ))}
              </Select>
            </FormControl>
            <TextField sx={{ width: isMobile ? '100%' : '200px' }} label="LO/LA Sign Date" name="loLaSignDate" type="date" InputLabelProps={{ shrink: true }} value={formData.loLaSignDate} onChange={handleChange} margin="normal" />
            
          </Box>
        );
  
      case 3: // Documents
        return (
          <Box display="flex" flexDirection="column" alignItems="left" sx={{ border: '1px solid #ddd', borderRadius: '8px', padding: '16px', backgroundColor: '#f9f9f9' }}>
            <Typography variant="h6">Documents</Typography>
            <Typography variant="body1" gutterBottom>Booking Form URL *</Typography>
            <input type="file" name="bookingFormUrl" onChange={handleChange} />
            <Typography variant="body1" gutterBottom sx={{ mt: '20px' }}>SPA Copy URL</Typography>
            <input type="file" name="spaCopyUrl" onChange={handleChange} />
            <Typography variant="body1" gutterBottom sx={{ mt: '20px' }}>LO/LA Sign Copy URL</Typography>
            <input type="file" name="loLaSignCopyUrl" onChange={handleChange} />
            <Typography variant="body1" gutterBottom sx={{ mt: '20px' }}>Cancellation Form URL</Typography>
            <input type="file" name="cancellationFormUrl" onChange={handleChange} />
          </Box>
        );
  
      case 4: // Sales Agent & Remarks
      return (
        <Box display="flex" flexDirection="column" alignItems="left" sx={{ border: '1px solid #ddd', borderRadius: '8px', padding: '16px', backgroundColor: '#f9f9f9' }}>
          <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
            <Typography variant="h6">Sales Agent</Typography>
            <Button variant="contained" color="success" onClick={addAgent}>
              + Add Agent
            </Button>
          </Box>
          {formData.salesAgentShare.map((agent, index) => (
            <Box key={index} sx={{ mb: 2, border: '1px solid #ddd', borderRadius: '8px', padding: '16px', backgroundColor: '#fff' }}>
              <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                <Typography variant="body1">Sales Agent: {index + 1}</Typography>
                <Button
                  variant="contained"
                  color="error"
                  onClick={() => {
                    const updatedAgents = formData.salesAgentShare.filter((_, i) => i !== index);
                    setFormData({ ...formData, salesAgentShare: updatedAgents });
                  }}
                  sx={{ minWidth: '40px' }}
                >
                  -
                </Button>
              </Box>
              <Box display="flex" justifyContent="space-between" alignItems="center" gap={2}>
                <Box flex={1}>
                  <Typography variant="subtitle2" gutterBottom>Sales Agent</Typography>
                  <TextField
                    select
                    fullWidth
                    value={agent.uid}
                    onChange={(e) => handleAgentChange(index, 'uid', e.target.value)}
                    SelectProps={{ native: true }}
                  >
                    {/* Dynamically generate options from salesAgents */}
                    <option value="">Select Agent</option>
                    {salesAgents.map((salesAgent) => (
                      <option key={salesAgent.uid} value={salesAgent.uid}>
                        {salesAgent.name}
                      </option>
                    ))}
                  </TextField>
                </Box>
                <Box sx={{ width: '150px' }}>
                  <Typography variant="subtitle2" gutterBottom>Commission Share (%)</Typography>
                  <TextField
                    fullWidth
                    type="number"
                    value={agent.sharePercentage}
                    onChange={(e) => handleAgentChange(index, 'sharePercentage', e.target.value)}
                  />
                </Box>
              </Box>
            </Box>
          ))}
          <Typography variant="subtitle2" gutterBottom mt={2}>Remarks</Typography>
          <TextField
            fullWidth
            name="remark"
            value={formData.remark}
            onChange={handleChange}
            placeholder="Enter any remarks (optional)"
            multiline
            rows={3}
            variant="outlined"
          />
        </Box>
      );
  
      default:
        return 'Unknown Step';
    }
  };
  

  return (
    <Box sx={{ width: '100%' }}>
      <Button variant="contained" color="primary" onClick={() => navigate('/sales', { state: { tabIndex: 0 } })} sx={{ mb: 2 }}>
        Back
      </Button>
      <Typography variant="h4" gutterBottom>
        New Project Deal
      </Typography>
      <Stepper activeStep={activeStep} sx={{ mt: 4 }}>
        {steps.map((label, index) => (
          <Step key={index}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <Box sx={{ mt: 2 }}>
        {renderStepContent(activeStep)}
      </Box>
      <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between' }}>
        <Button disabled={activeStep === 0} onClick={handleBack}>
          Back
        </Button>
        {activeStep === steps.length - 1 ? (
          <Box sx={{ display: 'flex', gap: 2 }}>
            <Button variant="outlined" color="primary" onClick={handleDraft}>
              Draft
            </Button>
            <Button variant="contained" color="primary" onClick={handleSubmit} disabled={!isStepValid()}>
              Ready To Convert
            </Button>
          </Box>
        ) : (
          <Button variant="contained" color="primary" onClick={handleNext} disabled={!isStepValid()}>
            Next
          </Button>
        )}

      </Box>
      <Snackbar
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }} // Position Snackbar at the top center
      >
        <Alert onClose={() => setOpenSnackbar(false)} severity="success">
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default CreateProjectDealPage;
