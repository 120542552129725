import React, { useState, useEffect } from "react";
import TableComponent from "../../components/TableComponent";
import {
  IconButton,
  Typography,
  Button,
  Modal,
  Box,
  TextField,
  CircularProgress,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import DeleteIcon from "@mui/icons-material/Delete";
import { useAuth } from "../../context/AuthContext";
import { db, storage } from "../../firebase";
import { addDoc, collection, getDocs, doc, updateDoc } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";

const LeavesPage = () => {
  const [leaves, setLeaves] = useState([]);
  const [loading, setLoading] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [targetLeaveId, setTargetLeaveId] = useState(null);
  const [type, setType] = useState("");
  const [description, setDescription] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [attachment, setAttachment] = useState(null);
  const [saving, setSaving] = useState(false);
  const [leaveTypes] = useState(["Annual Leave", "Sick Leave", "Casual Leave", "Maternity Leave"]); // List of leave types
  const { currentUser, userData } = useAuth();

  const columns = [
    { id: "no", label: "No.", minWidth: 50, align: "center" },
    { id: "type", label: "Type", minWidth: 150, align: "left" },
    { id: "description", label: "Description", minWidth: 200, align: "left" },
    { id: "status", label: "Status", minWidth: 150, align: "center" },
    { id: "startDate", label: "Start Date", minWidth: 150, align: "center" },
    { id: "endDate", label: "End Date", minWidth: 150, align: "center" },
    {
      id: "action",
      label: "Action",
      minWidth: 150,
      align: "center",
      render: (row) => (
        <>
          <IconButton
            component="a"
            href={row.attachmentUrl}
            download
            target="_blank"
            rel="noopener noreferrer"
          >
            <DownloadIcon />
          </IconButton>
          {userData?.position === "Admin" && ( // Render Delete Icon for Admin Only
            <IconButton onClick={() => openConfirmationModal(row.id)}>
              <DeleteIcon />
            </IconButton>
          )}
        </>
      ),
    },
  ];

  useEffect(() => {
    const fetchLeaves = async () => {
      setLoading(true);
      try {
        const querySnapshot = await getDocs(collection(db, "leaves"));
        const fetchedLeaves = querySnapshot.docs
          .map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }))
          .filter((leave) => !leave.isDeleted) // Exclude deleted leaves
          .sort((a, b) => b.createdAt.seconds - a.createdAt.seconds);

        setLeaves(fetchedLeaves);
      } catch (error) {
        console.error("Error fetching leaves:", error);
        alert("Failed to fetch leaves. Please try again.");
      } finally {
        setLoading(false);
      }
    };

    fetchLeaves();
  }, []);

  const handleFileUpload = (event) => {
    setAttachment(event.target.files[0]);
  };

  const handleSaveLeave = async () => {
    if (!type || !description || !startDate || !endDate || !attachment) {
      alert("Please fill all fields and upload an attachment.");
      return;
    }

    setSaving(true);

    try {
      // Upload the attachment to Firebase Storage
      const fileRef = ref(storage, `leaves/${attachment.name}`);
      await uploadBytes(fileRef, attachment);
      const attachmentUrl = await getDownloadURL(fileRef);

      // Save leave data to Firestore
      const leaveData = {
        createdBy: currentUser.uid,
        createdAt: new Date(),
        isDeleted: false,
        deletedBy: null,
        deletedAt: null,
        type,
        description,
        startDate,
        endDate,
        status: "Pending",
        attachmentUrl,
      };

      const docRef = await addDoc(collection(db, "leaves"), leaveData);

      // Add the newly saved leave to the local state
      setLeaves((prev) => [...prev, { id: docRef.id, ...leaveData }]);

      // Reset fields and close modal
      setType("");
      setDescription("");
      setStartDate("");
      setEndDate("");
      setAttachment(null);
      setModalOpen(false);
    } catch (error) {
      console.error("Error saving leave:", error);
      alert("Failed to save leave. Please try again.");
    } finally {
      setSaving(false);
    }
  };

  const openConfirmationModal = (leaveId) => {
    setTargetLeaveId(leaveId);
    setConfirmationModalOpen(true);
  };

  const handleDeleteConfirmation = async () => {
    if (!targetLeaveId) return;

    try {
      const leaveDoc = doc(db, "leaves", targetLeaveId);
      await updateDoc(leaveDoc, {
        isDeleted: true,
        deletedAt: new Date(),
        deletedBy: currentUser.uid,
      });

      setLeaves((prev) =>
        prev.map((item) =>
          item.id === targetLeaveId
            ? { ...item, isDeleted: true, deletedAt: new Date(), deletedBy: currentUser.uid }
            : item
        )
      );

      alert("Leave deleted successfully.");
    } catch (error) {
      console.error("Error deleting leave:", error);
      alert("Failed to delete leave. Please try again.");
    } finally {
      setConfirmationModalOpen(false);
      setTargetLeaveId(null);
    }
  };

  const handleCancelConfirmation = () => {
    setConfirmationModalOpen(false);
    setTargetLeaveId(null);
  };

  return (
    <div>
      <Box>
        <Typography
          variant="h6"
          gutterBottom
          sx={{
            padding: 2,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          My Leaves
          <Button
            variant="contained"
            color="primary"
            onClick={() => setModalOpen(true)}
          >
            Apply Leave
          </Button>
        </Typography>
      </Box>
      <TableComponent columns={columns} data={leaves} loading={loading} />

      {/* Apply Leave Modal */}
      <Modal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        aria-labelledby="apply-leave-modal"
        aria-describedby="apply-leave-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "100%",
            maxWidth: "800px",
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography variant="h6" id="apply-leave-modal" sx={{ mb: 2 }}>
            Apply for Leave
          </Typography>
          <FormControl fullWidth margin="normal">
            <InputLabel>Leave Type</InputLabel>
            <Select
              value={type}
              onChange={(e) => setType(e.target.value)}
              label="Leave Type"
            >
              {leaveTypes.map((leaveType, index) => (
                <MenuItem key={index} value={leaveType}>
                  {leaveType}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            fullWidth
            label="Description"
            margin="normal"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
          <TextField
            fullWidth
            label="Start Date"
            type="date"
            margin="normal"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            fullWidth
            label="End Date"
            type="date"
            margin="normal"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            InputLabelProps={{ shrink: true }}
          />
          <Button variant="contained" component="label" sx={{ mt: 2 }}>
            Upload Attachment
            <input type="file" hidden onChange={handleFileUpload} />
          </Button>
          {attachment && <Typography sx={{ mt: 1 }}>{attachment.name}</Typography>}
          <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => setModalOpen(false)}
              sx={{ mr: 1 }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSaveLeave}
              disabled={saving}
            >
              {saving ? <CircularProgress size={24} /> : "Save"}
            </Button>
          </Box>
        </Box>
      </Modal>

      {/* Confirmation Modal */}
      <Modal
        open={confirmationModalOpen}
        onClose={handleCancelConfirmation}
        aria-labelledby="delete-confirmation-modal"
        aria-describedby="delete-confirmation-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography variant="h6" id="delete-confirmation-modal" sx={{ mb: 2 }}>
            Confirm Deletion
          </Typography>
          <Typography>
            Are you sure you want to delete this leave application?
          </Typography>
          <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
            <Button
              variant="outlined"
              color="primary"
              onClick={handleCancelConfirmation}
              sx={{ mr: 1 }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="error"
              onClick={handleDeleteConfirmation}
            >
              Confirm
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default LeavesPage;
