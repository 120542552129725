import React, { useEffect, useState } from 'react';
import { Box, IconButton, Typography, Button, Paper, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import TableComponent from '../../components/TableComponent';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useNavigate } from 'react-router-dom';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../../firebase';
import { useAuth } from '../../context/AuthContext';
import { StatusWrapper } from '../../components/StatusWrapper';

const RequestCommissionComponent = () => {
  const navigate = useNavigate();
  const { currentUser, userData } = useAuth();
  const [commissionData, setCommissionData] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [loading, setLoading] = useState(true);

  // Mapping collection names to route paths
  const routeMapping = {
    projectDeals: '/request/view-project-deal',
    rentalDeals: '/request/view-rental-deal',
    subsaleDeals: '/request/view-subsale-deal',
  };

  useEffect(() => {
    setLoading(true);

    const fetchData = async () => {
      // Fetch statuses from the "Status" collection
      const statusSnapshot = await getDocs(collection(db, 'status'));
      const statusMap = statusSnapshot.docs.reduce((acc, doc) => {
        acc[doc.id] = doc.data().Name; // Map project ID to project name
        return acc;
      }, {});

      try {
        const collections = ['projectDeals', 'rentalDeals', 'subsaleDeals'];
        let allData = [];

        for (let coll of collections) {
          const querySnapshot = await getDocs(collection(db, coll));
          const data = querySnapshot.docs
            .map((doc, index) => ({
              id: doc.id,
              no: index + 1,
              collection: coll,
              type: coll === 'projectDeals' ? 'Project' : coll === 'rentalDeals' ? 'Rental' : 'Subsale',
              ...doc.data(),
            }))
            .filter((doc) => doc.status === 3);
          allData = [...allData, ...data];
        }

        const usersSnapshot = await getDocs(collection(db, 'users'));
        const usersMap = {};
        usersSnapshot.forEach((userDoc) => {
          usersMap[userDoc.id] = userDoc.data().name;
        });

        const enrichedData = allData.map((item) => ({
          ...item,
          requestor: usersMap[item.createdBy] || 'Unknown',
          requestedAt: item.updatedAt ? new Date(item.updatedAt).toLocaleDateString() : 'N/A',
          status1: statusMap[item.status] || 'Unknown Status', // Replace or calculate status as needed
        }));

        if (userData.position === 'Admin') {
          setCommissionData(enrichedData);
        } else {
          const userSpecificData = enrichedData.filter((item) => item.createdBy === currentUser.uid);
          setCommissionData(userSpecificData);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [currentUser, userData]);

  // Handlers for the action buttons
  const handleView = (row) => {
    const route = `${routeMapping[row.collection]}/${row.id}`;
    navigate(route, { state: { fromRequests: true } });
  };

  const handleDeleteClick = (row) => {
    setSelectedRow(row);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedRow(null);
  };

  // Define columns for the table
  const columns = [
    { id: 'no', label: 'No.', minWidth: 50 },
    { id: 'requestor', label: 'Requestor', minWidth: 200 },
    { id: 'type', label: 'Type', minWidth: 100 }, // New "Type" column
    { id: 'requestedAt', label: 'Requested At', minWidth: 100 },
    {
      id: 'status1',
      label: 'Status',
      minWidth: 150,
      render: (row) => StatusWrapper(row.status, row.status1),
    },
    {
      id: 'action',
      label: 'Action',
      minWidth: 200,
      render: (row) => (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'left',
            alignItems: 'center',
            gap: 1,
          }}
        >
          <IconButton color="primary" onClick={() => handleView(row)}>
            <VisibilityIcon />
          </IconButton>
        </Box>
      ),
    },
  ];

  return (
    <Paper>
      <Typography
        variant="h6"
        gutterBottom
        sx={{ padding: 2, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
      >
        Commission Requests
       
      </Typography>
      <TableComponent columns={columns} data={commissionData} loading={loading} />

      {/* Confirmation Dialog */}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this request? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeleteClick} color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
};

export default RequestCommissionComponent;
