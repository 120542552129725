import React, { useState, useEffect } from 'react';
import { Box, Grid, Typography, Button, Avatar, TextField, CardContent, Card, Paper } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { doc, getDoc, getDocs, collection } from 'firebase/firestore';
import { db } from '../../../firebase'; // Your Firebase configuration
import { formatNumberWithCommas } from '../../../Helper/Helper';

const ViewProjectDealPage = () => {
  const navigate = useNavigate();
  const { id } = useParams(); // Use useParams to get the document ID from the URL
  const [projectDeal, setProjectDeal] = useState(null);
  const [agentNames, setAgentNames] = useState({});

  // Fetch the Project Deal from Firebase
  useEffect(() => {
    const fetchProjectDeal = async () => {
      try {
        const docRef = doc(db, 'projectDeals', id);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setProjectDeal(docSnap.data());
        } else {
          console.error('No such document!');
        }
      } catch (error) {
        console.error('Error fetching project deal:', error);
      }
    };

    const fetchAgentNames = async () => {
      try {
        const usersCollection = collection(db, 'users');
        const querySnapshot = await getDocs(usersCollection);
        const names = {};
        querySnapshot.forEach((doc) => {
          const data = doc.data();
          names[doc.id] = data.name;
        });
        setAgentNames(names);
      } catch (error) {
        console.error('Error fetching agent names:', error);
      }
    };

    fetchProjectDeal();
    fetchAgentNames();
  }, [id]);

  if (!projectDeal) {
    return <Typography>Loading...</Typography>;
  }

  const handleBackButton = () => {
    navigate('/sales', { state: { tabIndex: 0 } });
  };

  const renderDocumentField = (label, url) => {
    return (
      <TextField
        label={label}
        variant="outlined"
        fullWidth
        value={url ? 'View Document' : 'N/A'}
        InputProps={{
          readOnly: true,
          endAdornment: url && (
            <Button variant="text" href={url} target="_blank">
              View
            </Button>
          ),
        }}
        sx={{ mb: 2 }}
      />
    );
  };

  return (
    <Box sx={{ p: 3, border: '1px solid #ddd', borderRadius: '8px', backgroundColor: '#fff', position: 'relative' }}>
      {/* Sticky Note for Comments */}
      {projectDeal.comments && projectDeal.status === 6 && (
        <Paper
          elevation={3}
          sx={{
            position: 'absolute',
            top: 16,
            right: 16,
            width: 800,
            padding: 2,
            backgroundColor: '#FFF176', // Yellow color
            color: '#000',
            borderRadius: '8px',
            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
          }}
        >
          <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mb: 1 }}>
            Admin Comments:
          </Typography>
          <Typography variant="body2">{projectDeal.comments}</Typography>
        </Paper>
      )}

      <Button
        variant="contained"
        color="primary"
        onClick={handleBackButton}
        sx={{ mb: 2, marginBottom: '50px' }}
      >
        Back
      </Button>
      <Typography variant="h6" gutterBottom sx={{ mb: 2, marginBottom: '20px' }}>
        Project Deal Information
      </Typography>
      <Grid container spacing={2}>
        {/* Left Column */}
        <Grid item xs={12} sm={4}>
          <TextField
            label="Purchaser Name"
            variant="outlined"
            fullWidth
            value={projectDeal.purchaserName || 'N/A'}
            InputProps={{ readOnly: true }}
          />
          <TextField
            label="Project"
            variant="outlined"
            fullWidth
            value={projectDeal.project || 'N/A'}
            InputProps={{ readOnly: true }}
            sx={{ mt: 2 }}
          />
          <TextField
            label="Lot Number"
            variant="outlined"
            fullWidth
            value={projectDeal.lotNumber || 'N/A'}
            InputProps={{ readOnly: true }}
            sx={{ mt: 2 }}
          />
          <TextField
            label="SPA Price (RM)"
            variant="outlined"
            fullWidth
            value={formatNumberWithCommas(projectDeal.spaPrice || 0)}
            InputProps={{ readOnly: true }}
            sx={{ mt: 2 }}
          />
          <TextField
            label="Nett Price (RM)"
            variant="outlined"
            fullWidth
            value={formatNumberWithCommas(projectDeal.nettPrice || 0)}
            InputProps={{ readOnly: true }}
            sx={{ mt: 2 }}
          />
          <TextField
            label={projectDeal.rebate.type === '%' ? 'Rebate (%)' : 'Rebate (RM)'}
            variant="outlined"
            fullWidth
            value={projectDeal.rebate.value || 'N/A'}
            InputProps={{ readOnly: true }}
            sx={{ mt: 2 }}
          />
          <TextField
            label="SPA Date"
            variant="outlined"
            fullWidth
            value={projectDeal.spaDate || 'N/A'}
            InputProps={{ readOnly: true }}
            sx={{ mt: 2 }}
          />
        </Grid>

        {/* Middle Column */}
        <Grid item xs={12} sm={4}>
          <TextField
            label="Source"
            variant="outlined"
            fullWidth
            value={projectDeal.source || 'N/A'}
            InputProps={{ readOnly: true }}
          />
          <TextField
            label="Referral Name"
            variant="outlined"
            fullWidth
            value={projectDeal.referralName || 'N/A'}
            InputProps={{ readOnly: true }}
            sx={{ mt: 2 }}
          />
          <TextField
            label="Co-Agency"
            variant="outlined"
            fullWidth
            value={projectDeal.coAgency || 'N/A'}
            InputProps={{ readOnly: true }}
            sx={{ mt: 2 }}
          />
          <TextField
            label="Loan Margin"
            variant="outlined"
            fullWidth
            value={projectDeal.loanMargin || 'N/A'}
            InputProps={{ readOnly: true }}
            sx={{ mt: 2 }}
          />
          <TextField
            label="LO/LA Status"
            variant="outlined"
            fullWidth
            value={projectDeal.loLaStatus || 'N/A'}
            InputProps={{ readOnly: true }}
            sx={{ mt: 2 }}
          />
          <TextField
            label="LO/LA Sign Date"
            variant="outlined"
            fullWidth
            value={projectDeal.loLaSignDate || 'N/A'}
            InputProps={{ readOnly: true }}
            sx={{ mt: 2 }}
          />
        </Grid>

        {/* Right Column */}
        <Grid item xs={12} sm={4}>
          {renderDocumentField('Booking Form', projectDeal.bookingFormUrl)}
          {renderDocumentField('SPA Copy', projectDeal.spaCopyUrl)}
          {renderDocumentField('LO/LA Signed Copy', projectDeal.loLaSignCopyUrl)}
          {renderDocumentField('Cancellation Form', projectDeal.cancellationFormUrl)}
        </Grid>
      </Grid>

      {/* Agents Information */}
      <Card sx={{ mt: 4 }}>
        <CardContent>
          <Typography variant="h6" gutterBottom>
            Agents Information
          </Typography>
          {projectDeal.salesAgentShare.map((agent, index) => (
            <Box key={index} display="flex" alignItems="center" sx={{ mb: 2 }}>
              <Avatar sx={{ bgcolor: '#3f51b5', mr: 2 }}>
                {agentNames[agent.uid]?.[0] || 'A'}
              </Avatar>
              <Box>
                <Typography>
                  Sales Agent: {agentNames[agent.uid] || agent.uid}
                </Typography>
                <Typography>
                  Commission Share: {agent.sharePercentage}%
                </Typography>
              </Box>
            </Box>
          ))}
          <TextField
            label="Remarks"
            variant="outlined"
            fullWidth
            value={projectDeal.remark || 'N/A'}
            InputProps={{ readOnly: true }}
          />
        </CardContent>
      </Card>
    </Box>
  );
};

export default ViewProjectDealPage;
