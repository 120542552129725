import React, { useState, useEffect } from "react";
import TableComponent from "../../components/TableComponent";
import {
  IconButton,
  Typography,
  Button,
  Modal,
  Box,
  TextField,
  CircularProgress,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { useAuth } from "../../context/AuthContext";
import { db } from "../../firebase";
import {
  addDoc,
  collection,
  getDocs,
  doc,
  updateDoc,
} from "firebase/firestore";

const OfficeSlotsPage = () => {
  const [bookings, setBookings] = useState([]);
  const [venues, setVenues] = useState([]);
  const [users, setUsers] = useState({});
  const [loading, setLoading] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [targetBookingId, setTargetBookingId] = useState(null);
  const [venue, setVenue] = useState("");
  const [bookingDate, setBookingDate] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [saving, setSaving] = useState(false);
  const [searchQuery, setSearchQuery] = useState(""); // State for search input
  const { currentUser } = useAuth();

  const columns = [
    { id: "no", label: "No.", minWidth: 50, align: "center" },
    { id: "username", label: "Username", minWidth: 150, align: "left" },
    { id: "venue", label: "Venue", minWidth: 150, align: "left" },
    { id: "bookingDate", label: "Booking Date", minWidth: 150, align: "left" },
    { id: "startTime", label: "Start Time", minWidth: 100, align: "center" },
    { id: "endTime", label: "End Time", minWidth: 100, align: "center" },
    { id: "status", label: "Status", minWidth: 100, align: "center" },
    {
      id: "action",
      label: "Action",
      minWidth: 150,
      align: "center",
      render: (row) => (
        <>
          {currentUser.uid === row.createdBy && (
            <IconButton onClick={() => openConfirmationModal(row.id)}>
              <DeleteIcon />
            </IconButton>
          )}
        </>
      ),
    },
  ];

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const bookingsSnapshot = await getDocs(collection(db, "BookingSlots"));
        const fetchedBookings = bookingsSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        const venuesSnapshot = await getDocs(collection(db, "VenueSlots"));
        const fetchedVenues = venuesSnapshot.docs.map((doc) => ({
          id: doc.id,
          name: doc.data().Name,
        }));

        const usersSnapshot = await getDocs(collection(db, "users"));
        const fetchedUsers = {};
        usersSnapshot.docs.forEach((doc) => {
          fetchedUsers[doc.id] = doc.data().name;
        });

        setBookings(fetchedBookings);
        setVenues(fetchedVenues);
        setUsers(fetchedUsers);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleSaveBooking = async () => {
    if (!venue || !bookingDate || !startTime || !endTime) {
      alert("Please fill all fields.");
      return;
    }

    setSaving(true);

    try {
      const bookingData = {
        venue,
        bookingDate,
        startTime,
        endTime,
        createdAt: new Date(),
        createdBy: currentUser.uid,
        isDeleted: 0,
        deletedAt: null,
        deletedBy: null,
        status: "Booked",
      };

      const docRef = await addDoc(collection(db, "BookingSlots"), bookingData);

      setBookings((prev) => [
        ...prev,
        { id: docRef.id, ...bookingData, username: users[currentUser.uid] },
      ]);

      setVenue("");
      setBookingDate("");
      setStartTime("");
      setEndTime("");
      setModalOpen(false);
    } catch (error) {
      console.error("Error saving booking:", error);
      alert("Failed to save booking.");
    } finally {
      setSaving(false);
    }
  };

  const openConfirmationModal = (bookingId) => {
    setTargetBookingId(bookingId);
    setConfirmationModalOpen(true);
  };

  const handleDeleteConfirmation = async () => {
    if (!targetBookingId) return;

    try {
      const bookingDoc = doc(db, "BookingSlots", targetBookingId);
      await updateDoc(bookingDoc, {
        isDeleted: 1,
        deletedAt: new Date(),
        deletedBy: currentUser.uid,
      });

      setBookings((prev) =>
        prev.map((item) =>
          item.id === targetBookingId
            ? { ...item, isDeleted: 1, deletedAt: new Date(), deletedBy: currentUser.uid }
            : item
        )
      );

      alert("Booking deleted successfully.");
    } catch (error) {
      console.error("Error deleting booking:", error);
    } finally {
      setConfirmationModalOpen(false);
      setTargetBookingId(null);
    }
  };

  const filteredBookings = bookings
    .filter((item) => item.isDeleted === 0)
    .map((item) => ({
      ...item,
      username: users[item.createdBy] || "Unknown",
    }))
    .filter((item) =>
      item.username.toLowerCase().includes(searchQuery.toLowerCase())
    ); // Apply search filter

  return (
    <div>
      <Typography
        variant="h6"
        gutterBottom
        sx={{
          padding: 2,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        Office Slot Bookings
        <Button variant="contained" color="primary" onClick={() => setModalOpen(true)}>
          Book Slot
        </Button>
      </Typography>

      <TextField
        placeholder="Search by username"
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        sx={{
            padding: "10px", // Adjust padding around the TextField
            ".MuiInputBase-root": {
              height: "40px", // Set a shorter height for the input field
            },
          }}
      />

      <TableComponent
        columns={columns}
        data={filteredBookings.map((item, index) => ({
          ...item,
          no: index + 1,
        }))}
        loading={loading}
      />

      <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "100%",
            maxWidth: "600px",
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography variant="h6">Book Slot</Typography>
          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel>Venue</InputLabel>
            <Select
              value={venue}
              onChange={(e) => setVenue(e.target.value)}
              label="Venue"
            >
              {venues.map((venue) => (
                <MenuItem key={venue.id} value={venue.name}>
                  {venue.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            fullWidth
            label="Booking Date"
            type="date"
            value={bookingDate}
            onChange={(e) => setBookingDate(e.target.value)}
            sx={{ mb: 2 }}
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            fullWidth
            label="Start Time"
            type="time"
            value={startTime}
            onChange={(e) => setStartTime(e.target.value)}
            sx={{ mb: 2 }}
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            fullWidth
            label="End Time"
            type="time"
            value={endTime}
            onChange={(e) => setEndTime(e.target.value)}
            sx={{ mb: 2 }}
            InputLabelProps={{ shrink: true }}
          />
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Button variant="outlined" sx={{ mr: 1 }} onClick={() => setModalOpen(false)}>
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSaveBooking}
              disabled={saving}
            >
              {saving ? <CircularProgress size={24} /> : "Save"}
            </Button>
          </Box>
        </Box>
      </Modal>

      {/* Confirmation Modal */}
      <Modal
        open={confirmationModalOpen}
        onClose={() => setConfirmationModalOpen(false)}
        aria-labelledby="delete-confirmation-modal"
        aria-describedby="delete-confirmation-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography variant="h6" id="delete-confirmation-modal" sx={{ mb: 2 }}>
            Confirm Deletion
          </Typography>
          <Typography>
            Are you sure you want to delete this booking?
          </Typography>
          <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => setConfirmationModalOpen(false)}
              sx={{ mr: 1 }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="error"
              onClick={handleDeleteConfirmation}
            >
              Confirm
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default OfficeSlotsPage;
