import React, { useEffect, useState } from 'react';
import { Box, IconButton, Typography, Button, Paper, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@mui/material';
import TableComponent from '../../components/TableComponent'; // Your reusable table component
import VisibilityIcon from '@mui/icons-material/Visibility'; // View icon
import EditIcon from '@mui/icons-material/Edit'; // Edit icon
import DeleteIcon from '@mui/icons-material/Delete'; // Delete icon
import { useNavigate } from 'react-router-dom';
import { collection, getDocs, deleteDoc, doc } from 'firebase/firestore';
import { db } from '../../firebase'; // Your firebase config file
import { useAuth } from '../../context/AuthContext';
import { StatusWrapper } from '../../components/StatusWrapper';

const Project = () => {
  const navigate = useNavigate();
  const { currentUser, userData } = useAuth();
  const [projectData, setProjectData] = useState([]);
  const [searchQuery, setSearchQuery] = useState(''); // State for search query
  const [open, setOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [loading, setLoading] = useState(true); // Loading state

  useEffect(() => {
    setLoading(true);
    const fetchProjectData = async () => {
      try {
        // Fetch project names and IDs from the "projects" collection
        const projectSnapshot = await getDocs(collection(db, 'projects'));
        const projectMap = projectSnapshot.docs.reduce((acc, doc) => {
          acc[doc.id] = doc.data().name; // Map project ID to project name
          return acc;
        }, {});

        // Fetch statuses from the "Status" collection
        const statusSnapshot = await getDocs(collection(db, 'status'));
        const statusMap = statusSnapshot.docs.reduce((acc, doc) => {
          acc[doc.id] = doc.data().Name; // Map project ID to project name
          return acc;
        }, {});

        // Fetch project deals
        const querySnapshot = await getDocs(collection(db, 'projectDeals'));
        const projects = querySnapshot.docs.map((doc, index) => ({
          no: index + 1,
          id: doc.id,
          projectDealId: doc.data().projectDealId,
          purchaserName: doc.data().purchaserName,
          projectId: doc.data().projectId, // Keep the project ID
          project: projectMap[doc.data().projectId] || 'Unknown Project', // Match project name or default to "Unknown Project"
          lotNumber: doc.data().lotNumber,
          status: doc.data().status,
          status1: statusMap[doc.data().status] || 'Unknown Status', // Replace or calculate status as needed
          bookingDate: doc.data().bookingDate, // Include other fields as needed
          ...doc.data(),
        }));

        // Filter projects based on user role
        if (userData.position === 'Admin') {
          setProjectData(projects);
        } else {
          const userProjects = projects
            .filter((project) => project.createdBy === currentUser.uid)
            .sort((a, b) => a.projectDealId.localeCompare(b.projectDealId)); // Sort by projectDealId
          setProjectData(userProjects);
        }
      } catch (error) {
        console.error('Error fetching project data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchProjectData();
  }, [currentUser, userData.position]);

  // Filtered data based on search query
  const filteredData = projectData.filter((project) =>
    Object.values(project)
      .join(' ')
      .toLowerCase()
      .includes(searchQuery.toLowerCase())
  );

  // Handlers for the action buttons
  const handleView = (row) => {
    navigate(`/sales/view-project-deal/${row.id}`);
  };

  const handleEdit = (row) => {
    navigate(`/sales/edit-project-deal/${row.id}`);
  };

  const handleDelete = async () => {
    if (selectedRow) {
      try {
        // Delete the document from Firestore
        await deleteDoc(doc(db, 'projectDeals', selectedRow.id));
        // Update the local state to remove the deleted item
        setProjectData((prevData) => prevData.filter((item) => item.id !== selectedRow.id));
        setOpen(false); // Close the confirmation dialog
        setSelectedRow(null); // Clear the selected row
      } catch (error) {
        console.error('Error deleting document:', error);
      }
    }
  };

  const handleDeleteClick = (row) => {
    setSelectedRow(row);
    setOpen(true); // Open the confirmation dialog
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedRow(null);
  };

  const handleAddNewDeal = () => {
    navigate('/sales/create-project-deal');
  };

  // Define columns for the table
  const columns = [
    { id: 'no', label: 'No.', minWidth: 50 },
    { id: 'projectDealId', label: 'Project Order ID', minWidth: 150 },
    { id: 'lotNumber', label: 'Lot No.', minWidth: 150 },
    { id: 'purchaserName', label: 'Purchaser', minWidth: 150 },
    { id: 'project', label: 'Project', minWidth: 150 },
    {
      id: 'status1',
      label: 'Status',
      minWidth: 150,
      render: (row) => StatusWrapper(row.status, row.status1),
    },
    { id: 'bookingDate', label: 'Booking Date', minWidth: 150 },
    {
      id: 'action',
      label: 'Action',
      minWidth: 150,
      render: (row) => (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 1, // Add some space between the icons
          }}
        >
          <IconButton color="primary" onClick={() => handleView(row)}>
            <VisibilityIcon />
          </IconButton>
          {/* Conditionally render the edit button */}
          {row.status !== 'Pending' && row.status !== 'Approved' && (
            <IconButton color="secondary" onClick={() => handleEdit(row)}>
              <EditIcon />
            </IconButton>
          )}
          {row.status !== 'Pending' && row.status !== 'Approved' && (
            <IconButton color="error" onClick={() => handleDeleteClick(row)}>
              <DeleteIcon />
            </IconButton>
          )}
        </Box>
      ),
    },
  ];

  return (
    <Paper>
      <Typography variant="h6" gutterBottom sx={{ padding: 2, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        Project Deal List
        <Button variant="contained" color="primary" onClick={handleAddNewDeal}>
          Add New
        </Button>
      </Typography>

      {/* Search Input */}
      <TextField
        placeholder="Search..."
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        sx={{
          width: '250px',
          padding: '10px',
          marginBottom: '10px',
          '.MuiInputBase-root': {
            height: '40px', // Set a shorter height for the input field
          },
        }}
      />

      <TableComponent columns={columns} data={filteredData} loading={loading} />

      {/* Confirmation Dialog */}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this rental deal? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDelete} color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
};

export default Project;
