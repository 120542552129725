import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom"; // Import useNavigate
import { doc, getDoc } from "firebase/firestore";
import { Typography, Box, Button, CircularProgress } from "@mui/material";
import { db } from "../../firebase";

const ViewAnnouncementComponent = () => {
  const { id } = useParams(); // Get the announcement ID from the URL
  const navigate = useNavigate(); // Initialize navigate function
  const [announcement, setAnnouncement] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchAnnouncement = async () => {
      try {
        setLoading(true);
        const docRef = doc(db, "announcements", id);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          setAnnouncement(docSnap.data());
        } else {
          console.error("No such document!");
        }
      } catch (error) {
        console.error("Error fetching announcement:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchAnnouncement();
  }, [id]);

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (!announcement) {
    return (
      <Typography variant="h6" sx={{ textAlign: "center", marginTop: 4 }}>
        Announcement not found.
      </Typography>
    );
  }

  return (
    <div>
      {/* Back Button */}
      <Button variant="contained" color="primary" onClick={() => navigate(-1)} sx={{ mb: 2 }}>
        Back
      </Button>
        <Box
            sx={{
                padding: 4,
                maxWidth: 600,
                margin: "0 auto",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start", // Ensures left alignment
            }}
        >
            {/* Announcement Details */}
            <Typography variant="h4" gutterBottom align="left">
                {announcement.title}
            </Typography>
            <Typography
                variant="subtitle1"
                gutterBottom
                color="textSecondary"
                align="left"
            >
                Announcement Date:{" "}
                {new Date(announcement.createdAt.seconds * 1000).toLocaleString()}
            </Typography>
            <Typography variant="body1" gutterBottom align="left" sx={{marginTop:"20px"}}>
                {announcement.description}
            </Typography>
            {announcement.documentUrl && (
                <Button
                    variant="contained"
                    color="primary"
                    href={announcement.documentUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={{marginTop:"20px"}}
                >
                Download File
                </Button>
            )}
        </Box>
    </div>
  );
};

export default ViewAnnouncementComponent;
