import emailjs from 'emailjs-com';

export const sendEmail = async (userDetails, message, subject) => {
  const templateParams = {
    from_name: "LSV",
    from_email: "lsvproperty888@gmail.com", // Replace with your admin email
    subject: subject,
    message: message,
    to_name: userDetails.name,
    to_email: userDetails.email,
  };

  try {
    await emailjs.send(
      process.env.REACT_APP_EMAILJS_SERVICE_ID,
      process.env.REACT_APP_SUBMISSION_EMAILJS_TEMPLATE_ID,
      templateParams,
      process.env.REACT_APP_EMAILJS_USER_ID
    );
    console.log('Email sent successfully!');
  } catch (error) {
    console.error('Email sending error:', error);
  }
};
