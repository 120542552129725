import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Modal,
  TextField,
  Button,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  CircularProgress,
} from '@mui/material';
import { useAuth } from '../../context/AuthContext';
import { db } from '../../firebase';
import { doc, updateDoc, collection, getDocs } from 'firebase/firestore';
import AnnouncementsPage from './AnnouncementComponent';

const TSHIRT_SIZES = ['XS', 'S', 'M', 'L', 'XL', 'XXL', 'XXXL'];

const HomePage = () => {
  const { userData, currentUser } = useAuth();
  const [tshirtSize, setTshirtSize] = useState(userData?.tshirtSize || '');
  const [bankName, setBankName] = useState(userData?.bankName || '');
  const [bankAccNo, setBankAccNo] = useState(userData?.bankAccNo || '');
  const [recruiterId, setRecruiterId] = useState('');
  const [recruiterName, setRecruiterName] = useState(userData?.recruiterName || '');
  const [recruiters, setRecruiters] = useState([]);
  const [saving, setSaving] = useState(false);
  const [loadingRecruiters, setLoadingRecruiters] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false); // Initialize as false

  // Update modal state when userData changes
  useEffect(() => {
    if (userData?.isFirstTime === 1) {
      setIsModalOpen(true);
    }
  }, [userData]);

  // Fetch recruiters
  useEffect(() => {
    const fetchRecruiters = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, 'users'));
        const fetchedRecruiters = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          name: doc.data().name,
        }));
        setRecruiters(fetchedRecruiters);
      } catch (error) {
        console.error('Error fetching recruiters:', error);
        alert('Failed to load recruiters.');
      } finally {
        setLoadingRecruiters(false);
      }
    };

    if (isModalOpen && !recruiterName) {
      fetchRecruiters();
    }
  }, [isModalOpen, recruiterName]);

  const handleSubmit = async () => {
    if (!tshirtSize || !bankName || !bankAccNo || (!recruiterId && !recruiterName)) {
      alert('Please fill in all fields.');
      return;
    }

    setSaving(true);

    try {
      const userDoc = doc(db, 'users', currentUser.uid);
      await updateDoc(userDoc, {
        tshirtSize,
        bankName,
        bankAccNo,
        recruiterId,
        recruiterName: recruiterName || recruiters.find((r) => r.id === recruiterId)?.name || '',
        isFirstTime: 0,
        updatedAt: new Date(),
        updatedBy: currentUser?.displayName || '',
      });

      // Update local state or context to reflect the change
      userData.isFirstTime = 0;
      setIsModalOpen(false); // Close the modal
      alert('Information updated successfully.');
    } catch (error) {
      console.error('Error updating user information:', error);
      alert('Failed to update information. Please try again.');
    } finally {
      setSaving(false);
    }
  };

  return (
    <>
      {/* Modal for First-Time Users */}
      <Modal
        open={isModalOpen}
        aria-labelledby="first-time-modal"
        aria-describedby="first-time-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '100%',
            maxWidth: 600,
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography variant="h6" id="first-time-modal" sx={{ mb: 2 }}>
            Complete Your Profile
          </Typography>

          {recruiterName ? (
            <TextField
              fullWidth
              label="Recruiter"
              margin="normal"
              value={recruiterName}
              InputProps={{
                readOnly: true,
              }}
            />
          ) : (
            <FormControl fullWidth margin="normal">
              <InputLabel>Recruiter</InputLabel>
              <Select
                value={recruiterId}
                onChange={(e) => setRecruiterId(e.target.value)}
                label="Recruiter"
                disabled={loadingRecruiters}
              >
                {loadingRecruiters ? (
                  <MenuItem value="" disabled>
                    <CircularProgress size={24} />
                  </MenuItem>
                ) : (
                  recruiters.map((recruiter) => (
                    <MenuItem key={recruiter.id} value={recruiter.id}>
                      {recruiter.name}
                    </MenuItem>
                  ))
                )}
              </Select>
            </FormControl>
          )}

          <FormControl fullWidth margin="normal">
            <InputLabel>T-Shirt Size</InputLabel>
            <Select
              value={tshirtSize}
              onChange={(e) => setTshirtSize(e.target.value)}
              label="T-Shirt Size"
            >
              {TSHIRT_SIZES.map((size) => (
                <MenuItem key={size} value={size}>
                  {size}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <TextField
            fullWidth
            label="Bank Name"
            margin="normal"
            value={bankName}
            onChange={(e) => setBankName(e.target.value)}
          />
          <TextField
            fullWidth
            label="Bank Account Number"
            margin="normal"
            value={bankAccNo}
            onChange={(e) => setBankAccNo(e.target.value)}
          />

          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              disabled={saving}
            >
              {saving ? 'Saving...' : 'Submit'}
            </Button>
          </Box>
        </Box>
      </Modal>

      {/* Main Content */}
      {!isModalOpen && <AnnouncementsPage userData={userData} />}
    </>
  );
};

export default HomePage;
