import React, { useState, useEffect } from 'react';
import { Box, Grid, Typography, Button, Avatar, TextField, CardContent, Card, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { doc, getDoc, getDocs, collection, updateDoc } from 'firebase/firestore';
import { db } from '../../firebase'; // Your Firebase configuration
import { formatNumberWithCommas } from '../../Helper/Helper';
import useAuth from '../../hooks/useAuth';

const ViewRequestProject = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [projectDeal, setProjectDeal] = useState(null);
  const [agentNames, setAgentNames] = useState({});
  const [openModal, setOpenModal] = useState(false); // State to manage modal visibility
  const [comments, setComments] = useState(''); // State to capture admin comments
  const { user } = useAuth();

  useEffect(() => {
    const fetchProjectDeal = async () => {
      try {
        const docRef = doc(db, 'projectDeals', id);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setProjectDeal(docSnap.data());
        } else {
          console.error('No such document!');
        }
      } catch (error) {
        console.error('Error fetching project deal:', error);
      }
    };

    const fetchAgentNames = async () => {
      try {
        const usersCollection = collection(db, 'users');
        const querySnapshot = await getDocs(usersCollection);
        const names = {};
        querySnapshot.forEach((doc) => {
          const data = doc.data();
          names[doc.id] = data.name;
        });
        setAgentNames(names);
      } catch (error) {
        console.error('Error fetching agent names:', error);
      }
    };

    fetchProjectDeal();
    fetchAgentNames();
  }, [id]);

  const handleBackButton = () => {
    navigate('/requests', { state: { tabIndex: 0 } });
  };

  const handleStatusUpdate = async (status, adminComments = '') => {
    try {
      const docRef = doc(db, 'projectDeals', id);
      await updateDoc(docRef, { status, comments: adminComments });
      setProjectDeal((prev) => ({ ...prev, status, comments: adminComments })); // Update local state
      alert(`Status updated to ${status === 7 ? 'Converted' : 'Returned'} with comments.`);
      navigate('/requests', { state: { tabIndex: 0 } });
    } catch (error) {
      console.error('Error updating status:', error);
      alert('Failed to update status. Please try again.');
    }
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setComments(''); // Reset comments
  };

  const handleSubmitReturn = () => {
    handleStatusUpdate(6, comments); // Update status to Returned (6) with comments
    handleCloseModal(); // Close the modal
  };

  const renderDocumentField = (label, url) => {
    return (
      <TextField
        label={label}
        variant="outlined"
        fullWidth
        value={url ? 'View Document' : 'N/A'}
        InputProps={{
          readOnly: true,
          endAdornment: url && (
            <Button variant="text" href={url} target="_blank">
              View
            </Button>
          ),
        }}
        sx={{ mb: 2 }}
      />
    );
  };

  if (!projectDeal) {
    return <Typography>Loading...</Typography>;
  }

  return (
    <Box sx={{ p: 3, border: '1px solid #ddd', borderRadius: '8px', backgroundColor: '#fff' }}>
      <Button
        variant="contained"
        color="primary"
        onClick={handleBackButton}
        sx={{ mb: 2 }}
      >
        Back
      </Button>
      <Typography variant="h6" gutterBottom>
        Project Request Details
      </Typography>
      <Grid container spacing={2}>
        {/* Left Column */}
        <Grid item xs={12} sm={4}>
          <TextField
            label="Purchaser Name"
            variant="outlined"
            fullWidth
            value={projectDeal.purchaserName || 'N/A'}
            InputProps={{ readOnly: true }}
          />
          <TextField
            label="Project"
            variant="outlined"
            fullWidth
            value={projectDeal.project || 'N/A'}
            InputProps={{ readOnly: true }}
            sx={{ mt: 2 }}
          />
          <TextField
            label="Lot Number"
            variant="outlined"
            fullWidth
            value={projectDeal.lotNumber || 'N/A'}
            InputProps={{ readOnly: true }}
            sx={{ mt: 2 }}
          />
          <TextField
            label="SPA Price (RM)"
            variant="outlined"
            fullWidth
            value={formatNumberWithCommas(projectDeal.spaPrice || 0)}
            InputProps={{ readOnly: true }}
            sx={{ mt: 2 }}
          />
          <TextField
            label="Nett Price (RM)"
            variant="outlined"
            fullWidth
            value={formatNumberWithCommas(projectDeal.nettPrice || 0)}
            InputProps={{ readOnly: true }}
            sx={{ mt: 2 }}
          />
        </Grid>

        {/* Middle Column */}
        <Grid item xs={12} sm={4}>
          <TextField
            label="Source"
            variant="outlined"
            fullWidth
            value={projectDeal.source || 'N/A'}
            InputProps={{ readOnly: true }}
          />
          <TextField
            label="Referral Name"
            variant="outlined"
            fullWidth
            value={projectDeal.referralName || 'N/A'}
            InputProps={{ readOnly: true }}
            sx={{ mt: 2 }}
          />
          <TextField
            label="Co-Agency"
            variant="outlined"
            fullWidth
            value={projectDeal.coAgency || 'N/A'}
            InputProps={{ readOnly: true }}
            sx={{ mt: 2 }}
          />
          <TextField
            label="Remarks"
            variant="outlined"
            fullWidth
            value={projectDeal.remark || 'N/A'}
            InputProps={{ readOnly: true }}
            sx={{ mt: 2 }}
          />
        </Grid>

        {/* Right Column */}
        <Grid item xs={12} sm={4}>
          {renderDocumentField('Booking Form', projectDeal.bookingFormUrl)}
          {renderDocumentField('SPA Copy', projectDeal.spaCopyUrl)}
          {renderDocumentField('LO/LA Signed Copy', projectDeal.loLaSignCopyUrl)}
          {renderDocumentField('Cancellation Form', projectDeal.cancellationFormUrl)}
        </Grid>
      </Grid>

      {/* Agents Information */}
      <Card sx={{ mt: 4 }}>
        <CardContent>
          <Typography variant="h6" gutterBottom>
            Agents Information
          </Typography>
          {projectDeal.salesAgentShare.map((agent, index) => (
            <Box key={index} display="flex" alignItems="center" sx={{ mb: 2 }}>
              <Avatar sx={{ bgcolor: '#3f51b5', mr: 2 }}>
                {agentNames[agent.uid]?.[0] || 'A'}
              </Avatar>
              <Box>
                <Typography>
                  Sales Agent: {agentNames[agent.uid] || agent.uid}
                </Typography>
                <Typography>
                  Commission Share: {agent.sharePercentage}%
                </Typography>
              </Box>
            </Box>
          ))}
        </CardContent>
      </Card>

      {/* Approve and Return Buttons */}
      <Box display="flex" justifyContent="flex-end" gap={2} mt={4}>
        <Button
          variant="contained"
          color="success"
          onClick={() => handleStatusUpdate(7)} // Approve
        >
          Convert
        </Button>
        <Button
          variant="contained"
          color="error"
          onClick={handleOpenModal} // Open modal for Return
        >
          Return
        </Button>
      </Box>

      {/* Return Comments Modal */}
      <Dialog
        open={openModal}
        onClose={handleCloseModal}
        fullWidth // Ensures the dialog stretches to fill the available width
        maxWidth="md" // Set the maximum width to "medium" (you can also use "lg" for a larger width)
        sx={{
          '& .MuiDialog-paper': {
            minWidth: '600px', // Set minimum width to 600px (adjust as needed)
            maxWidth: '800px', // Set maximum width to 800px (adjust as needed)
          },
        }}
      >
        <DialogTitle>Provide Comments for Return</DialogTitle>
        <DialogContent>
          <TextField
            label="Comments"
            variant="outlined"
            fullWidth
            multiline
            rows={4}
            value={comments}
            onChange={(e) => setComments(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSubmitReturn} color="error">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ViewRequestProject;
